import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { NgoCampaignService } from 'src/app/shared/services/ngo-campaign/ngo-campaign.service';
import { NgoService } from 'src/app/shared/services/ngo/ngo.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-ngo-claim-create-list',
  templateUrl: './ngo-claim-create-list.component.html',
  styleUrl: './ngo-claim-create-list.component.scss',
})
export class NgoClaimCreateListComponent {
  public searchKeyword = '';

  public allRequests: any = [];

  public sessionUser = this.localStorageService.getSessionUser();

  constructor(
    private readonly ngoService: NgoService,
    private readonly spinner: NgxSpinnerService,
    private readonly utilitiesService: UtilitiesService,
    private readonly ngoCampaignService: NgoCampaignService,
    private readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.getAllRequests();
  }

  private getAllRequests() {
    this.allRequests = [];
    this.spinner.show();

    this.ngoService
      .getNgoByUserId('5dfae2ef-a45b-4a14-b240-dbfce7895590')
      .subscribe((result: any[]) => {
        this.allRequests = result;
        this.spinner.hide();
      });
  }

  private async approveRequest(index: string) {
    const response = await this.confirmAction(
      'You want to approve the request?',
      'Yes, Approve'
    );
    if (response.isConfirmed) {
      this.spinner.show();

      this.ngoCampaignService
        .approveNgoCampaignFeaturedRequest(this.allRequests[index].id, true)
        .subscribe({
          next: (res) => {
            this.spinner.hide();

            this.allRequests[index].is_featured = true;
            this.utilitiesService.showSwalWithToast(
              'Approved',
              'Campaign started successfully!'
            );
          },
          error: (err) => {
            this.spinner.hide();
            this.utilitiesService.showSwalWithToast(
              'Error',
              err.error.error_name,
              'error'
            );
          },
        });
    }
  }

  public buttonClick(e: any, campaign) {
    switch (e.itemData.id) {
      case 'edit_campaign':
        break;
      default:
        break;
    }
  }

  public get activeDataSource() {
    if (!this.searchKeyword) {
      return this.allRequests;
    }
    const keyword = this.searchKeyword.toLowerCase();
    return this.allRequests.filter((campaign) =>
      campaign.title.toLowerCase().includes(keyword)
    );
  }

  public onSearchKeywordChanged(event: any) {
    this.searchKeyword = event.event.target.value;
  }

  private async confirmAction(message: string, confirmButtonText: string) {
    return await this.utilitiesService.showSwalAndReturn({
      type: 'warning',
      title: 'Are you sure?',
      message: message,
      confirmButtonText: confirmButtonText,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });
  }
}
