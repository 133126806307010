import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevExtremeModule, DxTabPanelModule, DxTextBoxModule } from 'devextreme-angular';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { SuperAdminRoutingModule } from './super-admin.routing.module';
import { SuperAdminMenuComponent } from 'src/app/layout/super-admin-layout/super-admin-menu/super-admin-menu.component';
import { SuperAdminHeaderComponent } from 'src/app/layout/super-admin-layout/super-admin-header/super-admin-header.component';
import { RouterModule } from '@angular/router';
import { CampaignListComponent } from './campaign-list/campaign-list.component';
import { SAdminWorkplaceComponent } from './s-admin-workplace/s-admin-workplace.component';
import { CreateCampaignComponent } from './create-campaign/create-campaign.component';
import { DonationListComponent } from './donation-list/donation-list.component';
import { NgoCampaignFeatureRequestComponent } from './ngo-campaign/ngo-campaign-feature-request/ngo-campaign-feature-request.component';
import { SAdminCampaignDetailsComponent } from './ngo-campaign/s-admin-campaign-details/s-admin-campaign-details.component';
import { AllResponseComponent } from './all-response/all-response.component';
import { ContactRequestListComponent } from './contact-request/contact-request-list/contact-request-list.component';
import { NgoClaimCreateListComponent } from './ngo-campaign/ngo-claim-create-list/ngo-claim-create-list.component';

@NgModule({
  declarations: [
    SuperAdminHeaderComponent,
    SuperAdminMenuComponent,
    CampaignListComponent,
    CreateCampaignComponent,
    SAdminWorkplaceComponent,
    DonationListComponent,
    NgoCampaignFeatureRequestComponent,
    SAdminCampaignDetailsComponent,
    AllResponseComponent,
    ContactRequestListComponent,
    NgoClaimCreateListComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    SuperAdminRoutingModule,
    DevExtremeModule,
    FormsModule,
    SharedModule,
  ],
})
export class SuperAdminModule {}
