import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from 'src/app/shared/components/not-found/not-found.component';
import { SAdminWorkplaceComponent } from './s-admin-workplace/s-admin-workplace.component';
import { CampaignListComponent } from './campaign-list/campaign-list.component';
import { CreateCampaignComponent } from './create-campaign/create-campaign.component';
import { DonationListComponent } from './donation-list/donation-list.component';
import { NgoCampaignFeatureRequestComponent } from './ngo-campaign/ngo-campaign-feature-request/ngo-campaign-feature-request.component';
import { SAdminCampaignDetailsComponent } from './ngo-campaign/s-admin-campaign-details/s-admin-campaign-details.component';
import { AllResponseComponent } from './all-response/all-response.component';
import { ContactRequestListComponent } from './contact-request/contact-request-list/contact-request-list.component';
import { NgoClaimCreateListComponent } from './ngo-campaign/ngo-claim-create-list/ngo-claim-create-list.component';

const routes: Routes = [
  // {
  //   path: 'campaign-list',
  //   component: CampaignListComponent,
  //   pathMatch: 'full',
  // },
  // {
  //   path: 'create-new-campaign',
  //   component: CreateCampaignComponent,
  //   pathMatch: 'full',
  // },
  // {
  //   path: 'campaign/edit',
  //   component: CreateCampaignComponent,
  //   pathMatch: 'full',
  // },
  // {
  //   path: 'donations',
  //   component: DonationListComponent,
  //   pathMatch: 'full',
  // },
  {
    path: 'workplace',
    component: SAdminWorkplaceComponent,
    pathMatch: 'full',
  },
  {
    path: 'approval/ngo-claim-create-request',
    component: NgoClaimCreateListComponent,
    pathMatch: 'full',
  },
  {
    path: 'approval/campaign-feature-request',
    component: NgoCampaignFeatureRequestComponent,
    pathMatch: 'full',
  },
  {
    path: 'approval/campaign-details',
    component: SAdminCampaignDetailsComponent,
    pathMatch: 'full',
  },
  {
    path: 'approval/contact-requests',
    component: ContactRequestListComponent,
    pathMatch: 'full',
  },
  {
    path: 'all-donations',
    component: AllResponseComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: 'workplace',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SuperAdminRoutingModule {}
