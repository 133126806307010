import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactRequestService } from 'src/app/shared/services/contact-request/contact-request.service';

@Component({
  selector: 'app-contact-request-list',
  templateUrl: './contact-request-list.component.html',
  styleUrl: './contact-request-list.component.scss',
})
export class ContactRequestListComponent {
  public searchKeyword = '';

  public allRequests: any = [];

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly contactRequestService: ContactRequestService
  ) {}

  ngOnInit() {
    this.getAllRequests();
  }

  private getAllRequests() {
    this.allRequests = [];
    this.spinner.show();

    this.contactRequestService.getAllContactRequests().subscribe({
      next: (result) => {
        this.spinner.hide();
        this.allRequests = result;
      },
      error: (err) => {
        this.spinner.hide();
      },
    });
  }

  public get activeDataSource() {
    if (!this.searchKeyword) {
      return this.allRequests;
    }
    const keyword = this.searchKeyword.toLowerCase();
    return this.allRequests.filter((contact) =>
      contact.name.toLowerCase().includes(keyword)
    );
  }

  public onSearchKeywordChanged(event: any) {
    this.searchKeyword = event.event.target.value;
  }
}
