import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  ApprovalCategoryEnum,
  ApprovalStatusEnum,
} from 'src/app/shared/enum/approval-category.enum';
import { ApprovalService } from 'src/app/shared/services/approval.service';
import { NgoCampaignService } from 'src/app/shared/services/ngo-campaign/ngo-campaign.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-ngo-campaign-feature-request',
  templateUrl: './ngo-campaign-feature-request.component.html',
  styleUrl: './ngo-campaign-feature-request.component.scss',
})
export class NgoCampaignFeatureRequestComponent {
  public searchKeyword = '';
  public filterValue: any = 'ALL';
  public approvalStatusEnum = ApprovalStatusEnum;

  public mainList: any[] = [];
  public allRequests: any = [];

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly utilitiesService: UtilitiesService,
    private readonly ngoCampaignService: NgoCampaignService,
    private readonly approvalService: ApprovalService
  ) {}

  ngOnInit() {
    this.getAllRequests();
  }

  private getAllRequests() {
    this.allRequests = [];
    this.spinner.show();

    this.approvalService
      .getAllRequest(ApprovalCategoryEnum.CAMPAIGN)
      .subscribe({
        next: (result) => {
          this.spinner.hide();
          this.mainList = result;
          this.allRequests = result;
        },
        error: (err) => {
          this.spinner.hide();
        },
      });
  }

  public async takeAction(
    id: string,
    index: number,
    action: ApprovalStatusEnum
  ) {
    const response = await this.confirmAction(null, 'Yes');

    if (response.isConfirmed) {
      this.spinner.show();

      this.approvalService.takeApprovalAction(id, action).subscribe({
        next: (result) => {
          this.spinner.hide();

          let item = this.mainList.find((obj) => obj.id === id);
          item.status = action;
          this.allRequests[index].status = action;

          this.utilitiesService.showSwalWithToast('Approved', result.message);
        },
        error: (err) => {
          this.spinner.hide();
          this.utilitiesService.showSwalWithToast(
            err.error.error_name,
            err.error.message,
            'error'
          );
        },
      });
    }
  }

  public get activeDataSource() {
    if (!this.searchKeyword) {
      return this.allRequests;
    }
    const keyword = this.searchKeyword.toLowerCase();
    return this.allRequests.filter((campaign) =>
      campaign.searchValue.toLowerCase().includes(keyword)
    );
  }

  public onSearchKeywordChanged(event: any) {
    this.searchKeyword = event.event.target.value;
  }

  public filterValueChanged(e: any) {
    console.log(e);
    if (e?.selectedItem === 'ALL') {
      this.allRequests = JSON.parse(JSON.stringify(this.mainList));
    } else {
      this.allRequests = this.mainList.filter(
        (campaign) => campaign.status === e?.selectedItem
      );
    }
  }

  private async confirmAction(message: string, confirmButtonText: string) {
    return await this.utilitiesService.showSwalAndReturn({
      type: 'warning',
      title: 'Are you sure?',
      message: message,
      confirmButtonText: confirmButtonText,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });
  }
}
