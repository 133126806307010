<div class="main_div">
  <div class="header">
    <div class="header_text">
      <div class="donation_camp_text">NGO Claim/Create Requests</div>
    </div>
  </div>

  <!-- panel-title -->
  <div class="full_panel">
    <div class="col panel_right_side">
      <dx-text-box
        class="search_box"
        placeholder="Filter By keyword"
        [showClearButton]="true"
        (onInput)="onSearchKeywordChanged($event)"
        [buttons]="[
          {
            name: 'searchCampaign',
            location: 'before',
            options: {
              icon: 'find',
              elementAttr: {
                class: 'searchForm'
              }
            }
          }
        ]"
      >
      </dx-text-box>
    </div>
  </div>
  <!-- panel-title-end -->

  <!-- tab-panel-content -->
  <div class="panel_content_for_my_campaign">
    <div class="campaign_list">
      <div *ngFor="let campaign of activeDataSource; let i = index">
        <div class="campaign_card">
          <div
            class="title_div"
            [routerLink]="['/admin/campaign-details']"
            [queryParams]="{
              id: campaign?.id,
              ngo_id: campaign?.ngo_id,
              project_id: campaign?.project_id
            }"
            title="Details"
          >
            <div class="title_subtitle">
              <div class="title">{{ campaign?.name }}</div>
              <div class="subtitle">
                {{ campaign?.creation_type }}
              </div>
              <div class="subtitle" style="color: #939393">
                {{ campaign?.website }}
              </div>
            </div>
          </div>
          <div class="title_subtitle" style="width: 200px">
            <div class="status_child">
              <div class="status">
                User:
                <div class="status_text">{{ "User Name" }}</div>
              </div>
            </div>
            <div class="subtitle">
              {{ "+880-1900-000000" }}
            </div>
          </div>
          <div class="title_subtitle">
            <span class="form_date"
              >Created on:
              {{ campaign?.created_at | date : "dd MMM yyyy" }}</span
            >
            <div class="d-flex gap-2">
              <div class="subtitle">
                {{ "Pending" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- tab-panel-content-end -->

  <div
    *ngIf="allRequests.length === 0"
    class="No_form_container d-flex flex-column align-items-center"
    style="margin-top: 50px"
  >
    <img
      src="./assets/images/icon/donation_colorful_icon.svg"
      alt="donation-image"
      title="donations"
    />
    <span class="no_form_workspace">No feature requests yet</span>
  </div>
</div>
